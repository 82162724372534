<template>
	<v-container class="fill-height justify-center">
		<div class="loading-indicator" v-if="status==='loading'">
			<v-progress-circular color="primary" indeterminate :size="128"></v-progress-circular>
			<p class="pt-4 text-disabled">Überprüfe <span class="font-weight-bold">{{ ticketId }}</span></p>
		</div>

        <div class="text-left flex-column" v-else-if="status==='result'">
            <v-alert type="success">
                {{ message }}
            </v-alert>

            <v-btn class="btnAutoHeight mt-16" prepend-icon="mdi:mdi-qrcode-scan" size="x-large" @click="$emit('newScan')">weiteres Ticket scannen</v-btn>
		</div>


		<div class="text-left flex-column" v-else>
			<v-alert :type="status">
				{{ message!=='' ? message : 'Es ist ein unbekannter Fehler aufgetreten' }}
			</v-alert>

			<v-card class="mt-4" id="mainTicket" v-if="ticketName">
                <v-card-item>
                    <v-card-subtitle>Art</v-card-subtitle>
                    <v-card-title>{{ ticketName }}</v-card-title>
				</v-card-item>

                <v-card-actions v-if="actions.includes('transfer') || actions.includes('redeem')">
                    <v-btn block @click="ticketAction('redeem')" prepend-icon="mdi-ticket-confirmation" color="primary" variant="flat" class="btnAutoHeight font-weight-bold" v-if="actions.includes('redeem')">Dieses Ticket entwerten</v-btn>
                    <v-btn block @click="ticketAction('transfer')" prepend-icon="mdi-ticket-confirmation" color="primary" variant="flat" class="btnAutoHeight font-weight-bold" v-if="actions.includes('transfer')">Dieses Ticket übertragen & entwerten</v-btn>
                </v-card-actions>
            </v-card>

            <v-card class="mt-4" id="mainTicket" v-if="otherBookedTickets.length">
                <v-card-item>
                    <v-card-subtitle>Zu dieser Buchung gehören</v-card-subtitle>
                    <v-card-title>{{ otherBookedTickets.length }} weitere Tickets</v-card-title>
                </v-card-item>
				<v-card-text>
                    <v-list class="pa-0" density="compact" lines="one">
                        <v-list-item class="pa-0" v-for="item in otherBookedTickets" :key="item.id">{{ item.name }}</v-list-item>
					</v-list>
				</v-card-text>

                <v-card-actions v-if="actions.includes('redeemAll') || actions.includes('transferAll')">
                    <v-btn block @click="ticketAction('redeemAll')" prepend-icon="mdi-ticket-confirmation" color="secondary" variant="tonal" class="btnAutoHeight font-weight-bold" v-if="actions.includes('redeemAll')">Alle Tickets entwerten</v-btn>
					<v-btn block @click="ticketAction('transferAll')" prepend-icon="mdi-ticket-confirmation" color="secondary" variant="tonal" class="btnAutoHeight font-weight-bold" v-if="actions.includes('transferAll')">Alle Tickets übertragen & entwerten</v-btn>
                </v-card-actions>
            </v-card>

            <v-btn block max-width="100%" fab color="error" variant="flat" class="btnAutoHeight mt-4" v-if="actions.includes('transfer') || actions.includes('transferAll')" prepend-icon="mdi:mdi-qrcode-scan" @click="$emit('newScan')">abbrechen und neues Ticket scannen</v-btn>

			<InfoData :info-data="responseParameter"></InfoData>
		</div>

		<v-bottom-navigation :elevation="0">
			<v-btn prepend-icon="mdi:mdi-qrcode-scan" @click="$emit('newScan')">neuer Scan</v-btn>
		</v-bottom-navigation>
	</v-container>
</template>

<script>
import {API_URL, API_AUTH_USER, API_AUTH_TOKEN} from '../misc/properties'
import InfoData from './info-data.vue';
import axios from 'axios';

export default {
	name: 'check-result',
	components: {
		InfoData
	},
	props: {
		ticketId: {
			type: String,
			required: true
		},
		eventId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		status: 'loading',
		inRequest:  false,
		message: '',
		responseParameter: {},
		ticketName: '',
		requestKey: '',
		otherBookedTickets: [],
		actions: []
	}),

	watch: {
		ticketId() {
			this.onCheckResult();
		}
	},

	emits: ['newScan'],

	methods: {
		async onCheckResult() {
			if (!this.inRequest) {
				this.status = 'loading';
				this.message = '';
				this.responseParameter = {};
				this.ticketName = '';
				this.requestKey = '';
				this.otherBookedTickets = [];
				this.actions = [];
				this.inRequest = true;

				axios({
					method: 'POST',
					url: API_URL + 'checkTicket.html',
					responseType: 'json',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						code: this.ticketId,
						eventId: this.eventId,
						apiAuthUser: API_AUTH_USER,
						apiAuthToken: API_AUTH_TOKEN
					}
				})
					.then(response => {
						if ('status' in response.data) {
							this.status = response.data.status;
							if ('message' in response.data) {
								this.message = response.data.message;
							}
							if ('infoData' in response.data) {
								this.responseParameter = response.data.infoData;
							}
							if ('ticketName' in response.data) {
								this.ticketName = response.data.ticketName;
							}
							if ('requestKey' in response.data) {
								this.requestKey = response.data.requestKey;
							}
							if ('otherBookedTickets' in response.data) {
								this.otherBookedTickets = response.data.otherBookedTickets;
							}
							if ('actions' in response.data) {
								this.actions = response.data.actions;
							}
						} else {
							this.status = 'error';
						}
					})
					.catch(() => {
						this.status = 'error';
					})
					.finally( () => {
						this.inRequest = false;
					});
			}
		},

		async ticketAction(action) {
			if (!this.inRequest) {
				this.inRequest = true;
				this.status = 'loading';
				this.message = '';
				this.responseParameter = {};
				this.ticketName = '';
				this.actions = [];
				this.otherBookedTickets = [];

				axios({
					method: 'POST',
					url: API_URL + 'ticketAction.html',
					responseType: 'json',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						requestKey: this.requestKey,
						action: action,
						apiAuthUser: API_AUTH_USER,
						apiAuthToken: API_AUTH_TOKEN
					}
				})
					.then(response => {
						if ('status' in response.data && response.data.status=='success') {
							this.status = 'result';
						} else {
							this.status = 'error';
						}
						if ('message' in response.data) {
							this.message = response.data.message;
						}
					})
					.catch(() => {
						this.status = 'error';
					})
					.finally( () => {
						this.inRequest = false;
					});
			}
		}
	},

	created() {
		if (this.ticketId) {
			this.onCheckResult();
		}
	}
}
</script>

<style>
.btnAutoHeight.v-btn.v-btn--density-default {
	height: auto !important;
}
.btnAutoHeight .v-btn__content {
	white-space: normal;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}
</style>