import { createApp } from 'vue';
import App from './App.vue';

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const myCustomLightTheme = {
	dark: false,
	colors: {
		background: '#FFFFFF',
		surface: '#FFFFFF',
		primary: '#5b7b07',
		'primary-darken-1': '#445c05',
		secondary: '#d6be26',
		'secondary-darken-1': '#b19e3c',
		error: '#B00020',
		info: '#2196F3',
		success: '#4CAF50',
		warning: '#FB8C00',
	}
}

const vuetify = createVuetify({
	components,
	directives,
	theme: {
		defaultTheme: 'myCustomLightTheme',
		themes: {
			myCustomLightTheme,
		}
	},
	icons: {
		defaultSet: 'mdi', // This is already the default value - only for display purposes
	}
})

const myApp = createApp(App);
myApp.use(vuetify).mount('#app');
