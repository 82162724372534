<template>
	<div class="mt-16 text-caption">
		<v-table v-if="infoData.length" density="compact">
			<template v-slot:default>
				<thead>
				<tr>
					<th class="pl-2 pr-2 text-medium-emphasis text-left">Name</th>
					<th class="pl-0 pr-2 text-medium-emphasis text-left">Wert</th>
				</tr>
				</thead>
				<tbody>
				<tr class="text-disabled" v-for="item in infoData" :key="item.text">
					<td class="pl-2 pr-2">{{ item.text }}:</td>
					<td class="pl-0 pr-2">{{ item.value }}</td>
				</tr>
				</tbody>
			</template>
		</v-table>
	</div>

</template>

<script>
export default {
	name: 'InfoData',
	props: {
		infoData: {
			type: Array||Object,
			required: true
		}
	}
}
</script>