<template>
	<v-app id="inspire">
		<v-app-bar height="80">
			<v-toolbar-title>
				<div id="logo">
					<img src="../src/assets/logo-burg_green.png" width="560" height="200" alt="logo">
				</div>
			</v-toolbar-title>
			<v-app-bar-nav-icon @click="drawer = !drawer">
				<v-icon v-if="!drawer">mdi-menu</v-icon>
				<v-icon v-if="drawer">mdi-close</v-icon>
			</v-app-bar-nav-icon>
		</v-app-bar>
		<v-navigation-drawer width="300" theme="dark" class="text-left" v-model="drawer" location="right" temporary>
			<div class="pt-4 pl-4 pr-4 text-h6">Veranstaltungen</div>

			<v-list lines="two" class="pb-4" v-model="selectedEvent">
				<v-list-item v-for="event in events" :class="{ 'v-list-item--active': selectedEvent === event.id }" :key="event.id" :value="event.id" :title="event.title" :subtitle="event.subtitle" @click="selectedEvent = event.id">
					<template v-slot:prepend>
						<v-icon class="mr-4" v-if="selectedEvent!==event.id">mdi-checkbox-blank-circle-outline</v-icon>
						<v-icon class="mr-4" v-if="selectedEvent===event.id">mdi-checkbox-marked-circle-outline</v-icon>
					</template>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<div class="pt-4 pl-4 pr-4 pb-2 d-flex flex-row">
				<div class="label me-auto">Kamera</div>
				<div class="value font-weight-bold">
					<div v-if="camera === 'auto'">
						automatisch
					</div>
					<div v-else-if="camera === 'rear'">
						Rückseite
					</div>
					<div v-else-if="camera === 'front'">
						Vorderseite
					</div>
					<div v-else>
						deaktiviert
					</div>
				</div>
			</div>
			<v-btn variant="plain" block prepend-icon="mdi:mdi-camera-flip-outline" @click="switchCamera">Kamera wechseln</v-btn>

			<template v-slot:append>
				<v-divider></v-divider>
				<div class="pa-4 text-caption">
					<div class="text-disabled">App-Version: {{ appVersion }}</div>
					<div><a class="text-medium-emphasis" href="http://lohmann-robinski.de" target="_blank">Lohmann und Robinski GbR</a></div>
				</div>
			</template>
		</v-navigation-drawer>
		<v-main class="bg-grey-lighten-2">
			<QrCamera v-if="page==='scan'" :settings-camera="camera" :settings-visibility="page==='scan'" @scan-result="onScanResult"></QrCamera>
			<CheckResult v-if="page==='scanResult'" :ticket-id="result" :event-id="selectedEvent" :settings-visibility="page==='scanResult'" @new-scan="onNewScan"></CheckResult>

			<v-container class="fill-height" v-if="page==='scanError'">
			</v-container>
		</v-main>
		<template>
			<v-dialog v-model="dialogEventSelector" persistent max-width="480">
				<v-card>
					<v-card-title class="headline">Bitte eine Veranstaltung wählen</v-card-title>

					<v-list lines="two" class="pb-4" v-model="selectedEvent">
						<v-list-item v-for="(event, index) in events" :key="index" :value="event.id" :title="event.title" :subtitle="event.subtitle" @click="dialogEventSelector = false; selectedEvent = event.id">
							<template v-slot:prepend>
								<v-icon class="mr-4" v-if="selectedEvent!==event.id">mdi-checkbox-blank-circle-outline</v-icon>
								<v-icon class="mr-4" v-if="selectedEvent===event.id">mdi-checkbox-marked-circle-outline</v-icon>
							</template>
						</v-list-item>
					</v-list>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn class="primary" color="darken-1" text @click="dialogEventSelector = false">Jetzt nicht</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</v-app>
</template>

<script>
import QrCamera from './components/qr-camera.vue';
import CheckResult from './components/check-result.vue';
import axios from 'axios';
import './assets/css/fonts.css';
import { version as packageJsonVersion } from "../package.json";
import { API_URL, STORAGE_KEY_PREFIX } from './misc/properties'

export default {
	name: 'App',
	components: {
		QrCamera,
		CheckResult
	},
	data: () => ({
		camera: localStorage.getItem(STORAGE_KEY_PREFIX + 'Camera') || 'auto',
		drawer: null,
		page: 'scan',
		result: null,
		appVersion: packageJsonVersion || 0,
		events: [],
		selectedEvent: localStorage.getItem(STORAGE_KEY_PREFIX + 'SelectedEvent') || null,
		dialogEventSelector: false
	}),

	watch: {
		camera: {
			handler(newCam) {
				localStorage.setItem(STORAGE_KEY_PREFIX + 'Camera', newCam)
			},
			deep: true
		},
		selectedEvent: {
			handler(newValue) {
				localStorage.setItem(STORAGE_KEY_PREFIX + 'SelectedEvent', newValue)
			},
			deep: true
		},
		events: {
			handler(newEvents) {
				localStorage.setItem(STORAGE_KEY_PREFIX + 'Events', JSON.stringify(newEvents));

				var hasSelectedEvent = false,
					curDate = new Date(),
					dateMatch = false;
				for (var i in newEvents) {
					if (newEvents[i]['id']===this.selectedEvent) {
						hasSelectedEvent = true;
					}
					var dateStart = new Date(newEvents[i]['dateStart']),
						dateEnd = new Date(newEvents[i]['dateEnd']);
					if (dateStart>=curDate && dateEnd<=curDate) {
						dateMatch = newEvents[i]['id'];
					}
				}

				if (!hasSelectedEvent) {
					this.selectedEvent = null;
					if (dateMatch!==false) {
						this.selectedEvent = dateMatch;
					} else {
						this.dialogEventSelector = true;
					}
				}
			},
			deep: true
		}
	},

	methods: {
		switchCamera () {
			switch (this.camera) {
				case 'front':
				case 'auto':
					this.camera = 'rear';
					break;
				case 'rear':
					this.camera = 'front';
					break;
				default:
					this.camera = 'auto'
			}
		},

		formatDate(dateString) {
			const date = new Date(dateString);
			// Then specify how you want your dates to be formatted
			return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
		},

		parseJson (json) {
			var parsed;
			try {
				parsed = JSON.parse(json);
			} catch (e) {
				parsed = [];
			}
			return parsed;
		},

		getEvents () {
			axios({
				'method': 'get',
				'url': API_URL + 'getTicketEvents.html',
				'responseType': 'json'
			})
			.then(response => {
				this.events = [];
				for (var i in response.data) {
					response.data[i]['dateStartFormatted'] = this.formatDate(response.data[i]['dateStart']);
					response.data[i]['dateEndFormatted'] = this.formatDate(response.data[i]['dateEnd']);

					response.data[i]['subtitle'] = response.data[i]['dateEndFormatted']!=response.data[i]['dateStartFormatted'] ? response.data[i]['dateStartFormatted'] + ' - ' + response.data[i]['dateEndFormatted'] : response.data[i]['dateStartFormatted'];
					this.events.push(response.data[i]);
				}
			})
			.catch(error => {
				console.log(error)
			});
		},

		onScanResult (result) {
			if (result!=='') {
				this.result = result;
				this.page = 'scanResult';
			} else {
				this.onNewScan();
			}
		},

		onNewScan () {
			this.result = null;
			this.page = 'scan';
		}
	},

	mounted() {
		this.events = this.parseJson(localStorage.getItem(STORAGE_KEY_PREFIX + 'Events') || '' );
		this.getEvents();
	}
}
</script>

<style>
#logo {
	align-items: start;
	display: flex;
	justify-content: start;
	height: 100%;
	text-align: center;
}
#logo img {
	height: 60px;
	width: auto;
	flex-grow: 0;
}
</style>
