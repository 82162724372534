<template>
	<v-container class="fill-height pa-0">
		<qr-stream id="cam" :torch="torch" :camera="camera" @decode="onDecode" @init="onInit" v-if="camera!=='off'">
			<div class="loading-indicator" v-if="loading">
				<v-progress-circular color="primary" indeterminate :size="128"></v-progress-circular>
			</div>
			<v-alert type="warning" v-if="cameraError!=''" class="ma-4">{{ cameraError }}</v-alert>
		</qr-stream>

		<v-bottom-navigation :elevation="0" v-if="camera==='off'">
			<v-btn block @click="turnCameraOn">
				<v-icon>mdi-camera-outline</v-icon>
				Kamera einschalten
			</v-btn>
		</v-bottom-navigation>
		<v-bottom-navigation :elevation="0" v-if="camera!=='off'">
			<v-btn block v-if="torchIsSupported && !torch" @click="switchTorch">
				<v-icon>mdi-flashlight</v-icon>
				Licht einschalten
			</v-btn>
			<v-btn block v-if="torchIsSupported && torch" @click="switchTorch">
				<v-icon>mdi-flashlight-off</v-icon>
				Licht ausschalten
			</v-btn>
			<v-btn block click="turnCameraOff" @click="turnCameraOff">
				<v-icon>mdi-camera-off-outline</v-icon>
				Kamera ausschalten
			</v-btn>
		</v-bottom-navigation>
	</v-container>
</template>

<script>
import { QrStream } from 'vue3-qr-reader';
export default {
	name: 'QrCamera',

	components: {
		QrStream
	},

	props: {
		settingsCamera: String,
		settingsVisibility: Boolean
	},

	emits: ['scanResult'],

	data: () => ({
		loading: false,
		camera: 'auto',
		usedCamera: null,
		torch: false,
		torchIsSupported: false,
		cameraError: ''
	}),

	watch: {
		settingsCamera(newValue) {
			this.usedCamera = newValue;
			if (this.camera!=='off' && this.camera!=this.usedCamera) {
				this.turnCameraOn(false);
			}
		},
		settingsVisibility() {
			if (this.settingsVisibility) {
				this.turnCameraOn(false);
			} else {
				this.turnCameraOff();
			}
		}
	},

	methods: {
		async onInit (promise) {
			try {
				this.loading = true;
				this.cameraError = '';

				const { capabilities } = await promise;
				if ('torch' in capabilities) {
					this.torchIsSupported = true;
				} else {
					this.torchIsSupported = false;
				}
			} catch (error) {
				this.cameraError = error.message;
			} finally {
				this.loading = false;
			}
		},

		switchTorch () {
			if (this.torchIsSupported) {
				switch (this.torch) {
					case true:
						this.torch = false
						break
					case false:
						this.torch = true
						break
				}
			}
		},

		async onDecode (content) {
			if (content!='') {
				this.$emit('scanResult', content);
			}
			this.turnCameraOff()
		},

		async turnCameraOn (init) {
			if (this.settingsVisibility) {
				try {
					if (!init) {
						this.camera = 'off';
						await this.timeout(500);
					}
				} finally {
					this.camera = this.usedCamera;
				}
			} else {
				this.turnCameraOff();
			}
		},

		turnCameraOff () {
			this.camera = 'off';
		},

		timeout (ms) {
			return new Promise(resolve => {
				window.setTimeout(resolve, ms);
			})
		}
	},

	created() {
		if (this.settingsCamera) {
			this.usedCamera = this.settingsCamera;
			this.turnCameraOn(true);
		}
	}
}
</script>

<style scoped>
#cam .loading-indicator {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
</style>